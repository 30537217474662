.id7-utility-masthead {
  background-image: url("/dist/docs/site/docs-site/masthead-image.jpg");
}
body.id7-point-1 .id7-utility-masthead {
  background-image: none;
  background-color: white;
}
@media (min-width: 768px) {
  .id7-left-border {
    background-image: url("/dist/docs/site/docs-site/border-image-left.jpg");
  }
}
@media (min-width: 768px) {
  .id7-right-border {
    background-image: url("/dist/docs/site/docs-site/border-image-right.jpg");
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #41748D;
}
a,
a:link,
a:visited {
  color: #41748D;
}
a:hover,
a:focus,
a:active {
  color: #213b47;
}
.popover button.close {
  color: #41748D;
}
.popover button.close:hover {
  color: #213b47;
}
a:hover .new-window-link,
a:hover .insecure-link {
  color: #213b47;
}
.btn-link {
  color: #41748D;
}
.btn-link:hover,
.btn-link:focus {
  color: #213b47;
}
a,
a:link,
a:visited {
  -webkit-text-decoration-color: #7a9eaf;
  text-decoration-color: #7a9eaf;
}
a:hover,
a:focus,
a:active {
  -webkit-text-decoration-color: #213b47;
  text-decoration-color: #213b47;
}
blockquote.quotes {
  color: #213b47;
}
blockquote.quotes::before {
  color: #213b47;
}
.brand-bg {
  background: #41748D;
  color: white;
}
.brand-bg a,
.brand-bg a:link,
.brand-bg a:visited {
  color: white !important;
}
.brand-bg a:hover,
.brand-bg a:focus,
.brand-bg a:active {
  color: #cccccc !important;
}
.brand-bg .popover button.close {
  color: white !important;
}
.brand-bg .popover button.close:hover {
  color: #cccccc !important;
}
.brand-bg a:hover .new-window-link,
.brand-bg a:hover .insecure-link {
  color: #cccccc !important;
}
.brand-bg .btn-link {
  color: white !important;
}
.brand-bg .btn-link:hover,
.brand-bg .btn-link:focus {
  color: #cccccc !important;
}
.brand-bg a,
.brand-bg a:link,
.brand-bg a:visited {
  -webkit-text-decoration-color: #ffffff !important;
  text-decoration-color: #ffffff !important;
}
.brand-bg a:hover,
.brand-bg a:focus,
.brand-bg a:active {
  -webkit-text-decoration-color: #cccccc !important;
  text-decoration-color: #cccccc !important;
}
.brand-text {
  color: #41748D;
}
.brand-text a,
.brand-text a:link,
.brand-text a:visited {
  color: #41748D !important;
}
.brand-text a:hover,
.brand-text a:focus,
.brand-text a:active {
  color: #213b47 !important;
}
.brand-text .popover button.close {
  color: #41748D !important;
}
.brand-text .popover button.close:hover {
  color: #213b47 !important;
}
.brand-text a:hover .new-window-link,
.brand-text a:hover .insecure-link {
  color: #213b47 !important;
}
.brand-text .btn-link {
  color: #41748D !important;
}
.brand-text .btn-link:hover,
.brand-text .btn-link:focus {
  color: #213b47 !important;
}
.brand-text a,
.brand-text a:link,
.brand-text a:visited {
  -webkit-text-decoration-color: #7a9eaf !important;
  text-decoration-color: #7a9eaf !important;
}
.brand-text a:hover,
.brand-text a:focus,
.brand-text a:active {
  -webkit-text-decoration-color: #213b47 !important;
  text-decoration-color: #213b47 !important;
}
.brand-border {
  border-color: #41748D;
}
.btn-brand {
  color: white;
  background-color: #41748D;
  border-color: #39667c;
  color: white !important;
}
.btn-brand:focus,
.btn-brand.focus {
  color: white;
  background-color: #31576a;
  border-color: #111e24;
}
.btn-brand:hover {
  color: white;
  background-color: #31576a;
  border-color: #264352;
}
.btn-brand:active,
.btn-brand.active,
.open > .dropdown-toggle.btn-brand {
  color: white;
  background-color: #31576a;
  background-image: none;
  border-color: #264352;
}
.btn-brand:active:hover,
.btn-brand.active:hover,
.open > .dropdown-toggle.btn-brand:hover,
.btn-brand:active:focus,
.btn-brand.active:focus,
.open > .dropdown-toggle.btn-brand:focus,
.btn-brand:active.focus,
.btn-brand.active.focus,
.open > .dropdown-toggle.btn-brand.focus {
  color: white;
  background-color: #264352;
  border-color: #111e24;
}
.btn-brand.disabled:hover,
.btn-brand[disabled]:hover,
fieldset[disabled] .btn-brand:hover,
.btn-brand.disabled:focus,
.btn-brand[disabled]:focus,
fieldset[disabled] .btn-brand:focus,
.btn-brand.disabled.focus,
.btn-brand[disabled].focus,
fieldset[disabled] .btn-brand.focus {
  background-color: #41748D;
  border-color: #39667c;
}
.btn-brand .badge {
  color: #41748D;
  background-color: white;
}
.btn-brand:not(.btn-link) {
  text-decoration: none;
}
.id7-utility-masthead {
  background-color: #7a9eaf;
}
.id7-utility-masthead::after {
  background-color: #41748D;
}
body.id7-point-1 .id7-utility-masthead::after {
  background-color: transparent;
}
.id7-navigation .navbar-primary {
  background-color: #41748D;
}
.id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: white;
}
.id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.id7-navigation .navbar-primary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: white;
}
.id7-navigation .navbar-primary .navbar-text {
  color: white;
}
.id7-navigation .navbar-primary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: #afc5cf;
}
.id7-navigation .navbar-primary .navbar-nav > li {
  color: white;
}
.id7-navigation .navbar-primary .navbar-nav > li:hover,
.id7-navigation .navbar-primary .navbar-nav > li:focus {
  color: white;
  background-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-nav > li > a,
.id7-navigation .navbar-primary .navbar-nav > li > a:hover,
.id7-navigation .navbar-primary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.id7-navigation .navbar-primary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: #afc5cf;
}
.id7-navigation .navbar-primary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.id7-navigation .navbar-primary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.id7-navigation .navbar-primary .navbar-nav > .disabled,
.id7-navigation .navbar-primary .navbar-nav > .disabled:hover,
.id7-navigation .navbar-primary .navbar-nav > .disabled:focus {
  color: white;
  background-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-toggle {
  border: none;
  color: white;
}
.id7-navigation .navbar-primary .navbar-toggle:hover,
.id7-navigation .navbar-primary .navbar-toggle:focus {
  color: white;
  background: none;
}
.id7-navigation .navbar-primary .navbar-nav > .open,
.id7-navigation .navbar-primary .navbar-nav > .open:hover,
.id7-navigation .navbar-primary .navbar-nav > .open:focus {
  color: white;
  background-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu {
  background-color: #41748D;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu .divider {
  background-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a {
  color: white;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:hover,
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > li > a:focus {
  color: white;
  background-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a {
  background-color: #345d71;
  background-image: none;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: #afc5cf;
}
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a,
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:hover,
.id7-navigation .navbar-primary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: white;
  background-color: #345d71;
}
.id7-navigation .navbar-primary .navbar-link {
  color: white;
}
.id7-navigation .navbar-primary .navbar-link:hover {
  color: white;
}
.id7-navigation .navbar-primary .btn-link {
  color: white;
}
.id7-navigation .navbar-primary .btn-link:hover,
.id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.id7-navigation .navbar-primary .btn-link[disabled]:hover,
fieldset[disabled] .id7-navigation .navbar-primary .btn-link:hover,
.id7-navigation .navbar-primary .btn-link[disabled]:focus,
fieldset[disabled] .id7-navigation .navbar-primary .btn-link:focus {
  color: white;
}
.navbar-primary .navbar-brand {
  background-color: #41748D;
}
.navbar-secondary {
  background-color: #afc5cf;
}
.navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.navbar-secondary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.navbar-secondary .navbar-text {
  color: #202020;
}
.navbar-secondary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.navbar-secondary .navbar-nav > li {
  color: #202020;
}
.navbar-secondary .navbar-nav > li:hover,
.navbar-secondary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.navbar-secondary .navbar-nav > li > a,
.navbar-secondary .navbar-nav > li > a:hover,
.navbar-secondary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.navbar-secondary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.navbar-secondary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.navbar-secondary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.navbar-secondary .navbar-nav > .disabled,
.navbar-secondary .navbar-nav > .disabled:hover,
.navbar-secondary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.navbar-secondary .navbar-toggle {
  border: none;
  color: #202020;
}
.navbar-secondary .navbar-toggle:hover,
.navbar-secondary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.navbar-secondary .navbar-nav > .open,
.navbar-secondary .navbar-nav > .open:hover,
.navbar-secondary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.navbar-secondary .navbar-nav .dropdown-menu {
  background-color: #afc5cf;
}
.navbar-secondary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #8c9da6;
}
.navbar-secondary .navbar-nav .dropdown-menu .divider {
  background-color: #8c9da6;
}
.navbar-secondary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.navbar-secondary .navbar-nav .dropdown-menu > li > a:hover,
.navbar-secondary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.navbar-secondary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c7d6dd;
  background-image: none;
}
.navbar-secondary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.navbar-secondary .navbar-nav .dropdown-menu > .disabled > a,
.navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:hover,
.navbar-secondary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c7d6dd;
}
.navbar-secondary .navbar-link {
  color: #202020;
}
.navbar-secondary .navbar-link:hover {
  color: #202020;
}
.navbar-secondary .btn-link {
  color: #202020;
}
.navbar-secondary .btn-link:hover,
.navbar-secondary .btn-link:focus {
  color: #202020;
}
.navbar-secondary .btn-link[disabled]:hover,
fieldset[disabled] .navbar-secondary .btn-link:hover,
.navbar-secondary .btn-link[disabled]:focus,
fieldset[disabled] .navbar-secondary .btn-link:focus {
  color: #202020;
}
.navbar-secondary .navbar-nav > li::after {
  color: #41748D;
}
.navbar-secondary .navbar-nav > li:first-child {
  background-color: #41748D;
  color: white;
}
.navbar-secondary .navbar-nav > li:first-child:hover::after {
  border-right-color: #41748D;
}
.navbar-secondary .navbar-nav > li:first-child::after {
  border-color: transparent transparent transparent #41748D;
}
.navbar-tertiary {
  background-color: #f8f8f8;
}
.navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li {
  color: #202020;
}
.navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li.active,
.navbar-tertiary.navbar-primary.navbar-wrapped > .navbar-nav > li:hover {
  color: #202020;
}
.navbar-tertiary .navbar-text {
  color: #202020;
}
.navbar-tertiary .navbar-nav li.dropdown ul.dropdown-menu .active::after {
  border-left-color: white;
}
.navbar-tertiary .navbar-nav > li {
  color: #202020;
}
.navbar-tertiary .navbar-nav > li:hover,
.navbar-tertiary .navbar-nav > li:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav > li > a,
.navbar-tertiary .navbar-nav > li > a:hover,
.navbar-tertiary .navbar-nav > li > a:focus {
  color: inherit;
  background: none;
}
.navbar-tertiary .navbar-nav > li.active.next-secondary::before {
  border-bottom-color: white;
}
.navbar-tertiary .navbar-nav > li.active.next-tertiary::before {
  border-bottom-color: #f8f8f8;
}
.navbar-tertiary .navbar-nav > li.active::before {
  border-bottom-color: #FFFFFF;
}
.navbar-tertiary .navbar-nav > .disabled,
.navbar-tertiary .navbar-nav > .disabled:hover,
.navbar-tertiary .navbar-nav > .disabled:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-toggle {
  border: none;
  color: #202020;
}
.navbar-tertiary .navbar-toggle:hover,
.navbar-tertiary .navbar-toggle:focus {
  color: #202020;
  background: none;
}
.navbar-tertiary .navbar-nav > .open,
.navbar-tertiary .navbar-nav > .open:hover,
.navbar-tertiary .navbar-nav > .open:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu {
  background-color: #f8f8f8;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .dropdown-header {
  border-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu .divider {
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu > li > a {
  color: #202020;
}
.navbar-tertiary .navbar-nav .dropdown-menu > li > a:hover,
.navbar-tertiary .navbar-nav .dropdown-menu > li > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .active > a {
  background-color: #c6c6c6;
  background-image: none;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .active > a::before {
  border-bottom-color: white;
}
.navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a,
.navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:hover,
.navbar-tertiary .navbar-nav .dropdown-menu > .disabled > a:focus {
  color: #202020;
  background-color: #c6c6c6;
}
.navbar-tertiary .navbar-link {
  color: #202020;
}
.navbar-tertiary .navbar-link:hover {
  color: #202020;
}
.navbar-tertiary .btn-link {
  color: #202020;
}
.navbar-tertiary .btn-link:hover,
.navbar-tertiary .btn-link:focus {
  color: #202020;
}
.navbar-tertiary .btn-link[disabled]:hover,
fieldset[disabled] .navbar-tertiary .btn-link:hover,
.navbar-tertiary .btn-link[disabled]:focus,
fieldset[disabled] .navbar-tertiary .btn-link:focus {
  color: #202020;
}
.bordered .id7-left-border,
.bordered .id7-right-border {
  border-color: rgba(65, 116, 141, 0.3);
}
.id7-page-footer .id7-app-footer {
  background: #41748D;
  color: white;
}
.id7-page-footer .id7-app-footer h1,
.id7-page-footer .id7-app-footer h2,
.id7-page-footer .id7-app-footer h3,
.id7-page-footer .id7-app-footer h4,
.id7-page-footer .id7-app-footer h5,
.id7-page-footer .id7-app-footer h6,
.id7-page-footer .id7-app-footer .h1,
.id7-page-footer .id7-app-footer .h2,
.id7-page-footer .id7-app-footer .h3,
.id7-page-footer .id7-app-footer .h4,
.id7-page-footer .id7-app-footer .h5,
.id7-page-footer .id7-app-footer .h6 {
  color: white;
}
.id7-page-footer .id7-app-footer a,
.id7-page-footer .id7-app-footer a:link,
.id7-page-footer .id7-app-footer a:visited {
  color: white;
}
.id7-page-footer .id7-app-footer a:hover,
.id7-page-footer .id7-app-footer a:focus,
.id7-page-footer .id7-app-footer a:active {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer .popover button.close {
  color: white;
}
.id7-page-footer .id7-app-footer .popover button.close:hover {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer a:hover .new-window-link,
.id7-page-footer .id7-app-footer a:hover .insecure-link {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer .btn-link {
  color: white;
}
.id7-page-footer .id7-app-footer .btn-link:hover,
.id7-page-footer .id7-app-footer .btn-link:focus {
  color: #cccccc;
}
.id7-page-footer .id7-app-footer a,
.id7-page-footer .id7-app-footer a:link,
.id7-page-footer .id7-app-footer a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.id7-page-footer .id7-app-footer a:hover,
.id7-page-footer .id7-app-footer a:focus,
.id7-page-footer .id7-app-footer a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.id7-page-footer .id7-horizontal-divider .divider {
  stroke: #41748D;
}
.id7-page-footer.id7-footer-divider .id7-site-footer {
  background: white;
}
.id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::before,
.id7-page-footer.id7-footer-divider .id7-app-footer .id7-logo-bleed::after {
  border-top-color: white;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer {
  background: #d3d3d3;
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer h1,
.id7-page-footer.id7-footer-coloured .id7-site-footer h2,
.id7-page-footer.id7-footer-coloured .id7-site-footer h3,
.id7-page-footer.id7-footer-coloured .id7-site-footer h4,
.id7-page-footer.id7-footer-coloured .id7-site-footer h5,
.id7-page-footer.id7-footer-coloured .id7-site-footer h6,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h1,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h2,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h3,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h4,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h5,
.id7-page-footer.id7-footer-coloured .id7-site-footer .h6 {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .popover button.close:hover {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .new-window-link,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover .insecure-link {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link {
  color: #202020;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:hover,
.id7-page-footer.id7-footer-coloured .id7-site-footer .btn-link:focus {
  color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:link,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.id7-page-footer.id7-footer-coloured .id7-site-footer a:hover,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:focus,
.id7-page-footer.id7-footer-coloured .id7-site-footer a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::before,
.id7-page-footer.id7-footer-coloured .id7-app-footer .id7-logo-bleed::after {
  border-top-color: #d3d3d3;
}
.id7-search .fa,
.id7-search .fas {
  color: #41748D;
  color: rgba(65, 116, 141, 0.5);
  -webkit-transition: color 0.15s linear;
  -o-transition: color 0.15s linear;
  transition: color 0.15s linear;
}
.id7-search .form-control:hover + .fa,
.id7-search .typeahead:hover + .fa,
.id7-search .fa:hover,
.id7-search .form-control:hover + .fas,
.id7-search .typeahead:hover + .fas,
.id7-search .fas:hover {
  color: #41748D;
}
.id7-search .typeahead.dropdown-menu > li.active > a {
  background: #41748D;
  color: white;
}
.form-control:focus {
  border-color: #41748D;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(65, 116, 141, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(65, 116, 141, 0.6);
}
.carousel .carousel-indicators li::after {
  color: #41748D;
  color: rgba(65, 116, 141, 0.3);
}
.carousel .carousel-indicators li.active::after {
  color: #41748D;
}
@media (max-width: 767px) {
  .boxstyle_.box1,
  .boxstyle-sm.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .boxstyle-md.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .boxstyle-lg.box1 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.boxstyle_.box1,
.boxstyle-xs.box1,
.boxstyle-sm.box1,
.boxstyle-md.box1,
.boxstyle-lg.box1 {
  border: 0;
  background: #e3eaee;
  color: #202020;
}
@media print {
  .boxstyle_.box1,
  .boxstyle-xs.box1,
  .boxstyle-sm.box1,
  .boxstyle-md.box1,
  .boxstyle-lg.box1 {
    border: 1px solid #e3eaee;
  }
}
.boxstyle_.box1 h1,
.boxstyle-xs.box1 h1,
.boxstyle-sm.box1 h1,
.boxstyle-md.box1 h1,
.boxstyle-lg.box1 h1,
.boxstyle_.box1 h2,
.boxstyle-xs.box1 h2,
.boxstyle-sm.box1 h2,
.boxstyle-md.box1 h2,
.boxstyle-lg.box1 h2,
.boxstyle_.box1 h3,
.boxstyle-xs.box1 h3,
.boxstyle-sm.box1 h3,
.boxstyle-md.box1 h3,
.boxstyle-lg.box1 h3,
.boxstyle_.box1 h4,
.boxstyle-xs.box1 h4,
.boxstyle-sm.box1 h4,
.boxstyle-md.box1 h4,
.boxstyle-lg.box1 h4,
.boxstyle_.box1 h5,
.boxstyle-xs.box1 h5,
.boxstyle-sm.box1 h5,
.boxstyle-md.box1 h5,
.boxstyle-lg.box1 h5,
.boxstyle_.box1 h6,
.boxstyle-xs.box1 h6,
.boxstyle-sm.box1 h6,
.boxstyle-md.box1 h6,
.boxstyle-lg.box1 h6,
.boxstyle_.box1 .h1,
.boxstyle-xs.box1 .h1,
.boxstyle-sm.box1 .h1,
.boxstyle-md.box1 .h1,
.boxstyle-lg.box1 .h1,
.boxstyle_.box1 .h2,
.boxstyle-xs.box1 .h2,
.boxstyle-sm.box1 .h2,
.boxstyle-md.box1 .h2,
.boxstyle-lg.box1 .h2,
.boxstyle_.box1 .h3,
.boxstyle-xs.box1 .h3,
.boxstyle-sm.box1 .h3,
.boxstyle-md.box1 .h3,
.boxstyle-lg.box1 .h3,
.boxstyle_.box1 .h4,
.boxstyle-xs.box1 .h4,
.boxstyle-sm.box1 .h4,
.boxstyle-md.box1 .h4,
.boxstyle-lg.box1 .h4,
.boxstyle_.box1 .h5,
.boxstyle-xs.box1 .h5,
.boxstyle-sm.box1 .h5,
.boxstyle-md.box1 .h5,
.boxstyle-lg.box1 .h5,
.boxstyle_.box1 .h6,
.boxstyle-xs.box1 .h6,
.boxstyle-sm.box1 .h6,
.boxstyle-md.box1 .h6,
.boxstyle-lg.box1 .h6 {
  color: #202020;
}
.boxstyle_.box1 blockquote.quotes,
.boxstyle-xs.box1 blockquote.quotes,
.boxstyle-sm.box1 blockquote.quotes,
.boxstyle-md.box1 blockquote.quotes,
.boxstyle-lg.box1 blockquote.quotes {
  color: inherit;
}
.boxstyle_.box1 a,
.boxstyle-xs.box1 a,
.boxstyle-sm.box1 a,
.boxstyle-md.box1 a,
.boxstyle-lg.box1 a,
.boxstyle_.box1 a:link,
.boxstyle-xs.box1 a:link,
.boxstyle-sm.box1 a:link,
.boxstyle-md.box1 a:link,
.boxstyle-lg.box1 a:link,
.boxstyle_.box1 a:visited,
.boxstyle-xs.box1 a:visited,
.boxstyle-sm.box1 a:visited,
.boxstyle-md.box1 a:visited,
.boxstyle-lg.box1 a:visited {
  color: #202020;
}
.boxstyle_.box1 a:hover,
.boxstyle-xs.box1 a:hover,
.boxstyle-sm.box1 a:hover,
.boxstyle-md.box1 a:hover,
.boxstyle-lg.box1 a:hover,
.boxstyle_.box1 a:focus,
.boxstyle-xs.box1 a:focus,
.boxstyle-sm.box1 a:focus,
.boxstyle-md.box1 a:focus,
.boxstyle-lg.box1 a:focus,
.boxstyle_.box1 a:active,
.boxstyle-xs.box1 a:active,
.boxstyle-sm.box1 a:active,
.boxstyle-md.box1 a:active,
.boxstyle-lg.box1 a:active {
  color: #000000;
}
.boxstyle_.box1 .popover button.close,
.boxstyle-xs.box1 .popover button.close,
.boxstyle-sm.box1 .popover button.close,
.boxstyle-md.box1 .popover button.close,
.boxstyle-lg.box1 .popover button.close {
  color: #202020;
}
.boxstyle_.box1 .popover button.close:hover,
.boxstyle-xs.box1 .popover button.close:hover,
.boxstyle-sm.box1 .popover button.close:hover,
.boxstyle-md.box1 .popover button.close:hover,
.boxstyle-lg.box1 .popover button.close:hover {
  color: #000000;
}
.boxstyle_.box1 a:hover .new-window-link,
.boxstyle-xs.box1 a:hover .new-window-link,
.boxstyle-sm.box1 a:hover .new-window-link,
.boxstyle-md.box1 a:hover .new-window-link,
.boxstyle-lg.box1 a:hover .new-window-link,
.boxstyle_.box1 a:hover .insecure-link,
.boxstyle-xs.box1 a:hover .insecure-link,
.boxstyle-sm.box1 a:hover .insecure-link,
.boxstyle-md.box1 a:hover .insecure-link,
.boxstyle-lg.box1 a:hover .insecure-link {
  color: #000000;
}
.boxstyle_.box1 .btn-link,
.boxstyle-xs.box1 .btn-link,
.boxstyle-sm.box1 .btn-link,
.boxstyle-md.box1 .btn-link,
.boxstyle-lg.box1 .btn-link {
  color: #202020;
}
.boxstyle_.box1 .btn-link:hover,
.boxstyle-xs.box1 .btn-link:hover,
.boxstyle-sm.box1 .btn-link:hover,
.boxstyle-md.box1 .btn-link:hover,
.boxstyle-lg.box1 .btn-link:hover,
.boxstyle_.box1 .btn-link:focus,
.boxstyle-xs.box1 .btn-link:focus,
.boxstyle-sm.box1 .btn-link:focus,
.boxstyle-md.box1 .btn-link:focus,
.boxstyle-lg.box1 .btn-link:focus {
  color: #000000;
}
.boxstyle_.box1 a,
.boxstyle-xs.box1 a,
.boxstyle-sm.box1 a,
.boxstyle-md.box1 a,
.boxstyle-lg.box1 a,
.boxstyle_.box1 a:link,
.boxstyle-xs.box1 a:link,
.boxstyle-sm.box1 a:link,
.boxstyle-md.box1 a:link,
.boxstyle-lg.box1 a:link,
.boxstyle_.box1 a:visited,
.boxstyle-xs.box1 a:visited,
.boxstyle-sm.box1 a:visited,
.boxstyle-md.box1 a:visited,
.boxstyle-lg.box1 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.boxstyle_.box1 a:hover,
.boxstyle-xs.box1 a:hover,
.boxstyle-sm.box1 a:hover,
.boxstyle-md.box1 a:hover,
.boxstyle-lg.box1 a:hover,
.boxstyle_.box1 a:focus,
.boxstyle-xs.box1 a:focus,
.boxstyle-sm.box1 a:focus,
.boxstyle-md.box1 a:focus,
.boxstyle-lg.box1 a:focus,
.boxstyle_.box1 a:active,
.boxstyle-xs.box1 a:active,
.boxstyle-sm.box1 a:active,
.boxstyle-md.box1 a:active,
.boxstyle-lg.box1 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
@media (max-width: 767px) {
  .boxstyle_.box2,
  .boxstyle-sm.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .boxstyle-md.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .boxstyle-lg.box2 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.boxstyle_.box2,
.boxstyle-xs.box2,
.boxstyle-sm.box2,
.boxstyle-md.box2,
.boxstyle-lg.box2 {
  border: 0;
  background: #eeeeee;
  color: #202020;
}
@media print {
  .boxstyle_.box2,
  .boxstyle-xs.box2,
  .boxstyle-sm.box2,
  .boxstyle-md.box2,
  .boxstyle-lg.box2 {
    border: 1px solid #eeeeee;
  }
}
.boxstyle_.box2 h1,
.boxstyle-xs.box2 h1,
.boxstyle-sm.box2 h1,
.boxstyle-md.box2 h1,
.boxstyle-lg.box2 h1,
.boxstyle_.box2 h2,
.boxstyle-xs.box2 h2,
.boxstyle-sm.box2 h2,
.boxstyle-md.box2 h2,
.boxstyle-lg.box2 h2,
.boxstyle_.box2 h3,
.boxstyle-xs.box2 h3,
.boxstyle-sm.box2 h3,
.boxstyle-md.box2 h3,
.boxstyle-lg.box2 h3,
.boxstyle_.box2 h4,
.boxstyle-xs.box2 h4,
.boxstyle-sm.box2 h4,
.boxstyle-md.box2 h4,
.boxstyle-lg.box2 h4,
.boxstyle_.box2 h5,
.boxstyle-xs.box2 h5,
.boxstyle-sm.box2 h5,
.boxstyle-md.box2 h5,
.boxstyle-lg.box2 h5,
.boxstyle_.box2 h6,
.boxstyle-xs.box2 h6,
.boxstyle-sm.box2 h6,
.boxstyle-md.box2 h6,
.boxstyle-lg.box2 h6,
.boxstyle_.box2 .h1,
.boxstyle-xs.box2 .h1,
.boxstyle-sm.box2 .h1,
.boxstyle-md.box2 .h1,
.boxstyle-lg.box2 .h1,
.boxstyle_.box2 .h2,
.boxstyle-xs.box2 .h2,
.boxstyle-sm.box2 .h2,
.boxstyle-md.box2 .h2,
.boxstyle-lg.box2 .h2,
.boxstyle_.box2 .h3,
.boxstyle-xs.box2 .h3,
.boxstyle-sm.box2 .h3,
.boxstyle-md.box2 .h3,
.boxstyle-lg.box2 .h3,
.boxstyle_.box2 .h4,
.boxstyle-xs.box2 .h4,
.boxstyle-sm.box2 .h4,
.boxstyle-md.box2 .h4,
.boxstyle-lg.box2 .h4,
.boxstyle_.box2 .h5,
.boxstyle-xs.box2 .h5,
.boxstyle-sm.box2 .h5,
.boxstyle-md.box2 .h5,
.boxstyle-lg.box2 .h5,
.boxstyle_.box2 .h6,
.boxstyle-xs.box2 .h6,
.boxstyle-sm.box2 .h6,
.boxstyle-md.box2 .h6,
.boxstyle-lg.box2 .h6 {
  color: #202020;
}
.boxstyle_.box2 blockquote.quotes,
.boxstyle-xs.box2 blockquote.quotes,
.boxstyle-sm.box2 blockquote.quotes,
.boxstyle-md.box2 blockquote.quotes,
.boxstyle-lg.box2 blockquote.quotes {
  color: inherit;
}
.boxstyle_.box2 a,
.boxstyle-xs.box2 a,
.boxstyle-sm.box2 a,
.boxstyle-md.box2 a,
.boxstyle-lg.box2 a,
.boxstyle_.box2 a:link,
.boxstyle-xs.box2 a:link,
.boxstyle-sm.box2 a:link,
.boxstyle-md.box2 a:link,
.boxstyle-lg.box2 a:link,
.boxstyle_.box2 a:visited,
.boxstyle-xs.box2 a:visited,
.boxstyle-sm.box2 a:visited,
.boxstyle-md.box2 a:visited,
.boxstyle-lg.box2 a:visited {
  color: #202020;
}
.boxstyle_.box2 a:hover,
.boxstyle-xs.box2 a:hover,
.boxstyle-sm.box2 a:hover,
.boxstyle-md.box2 a:hover,
.boxstyle-lg.box2 a:hover,
.boxstyle_.box2 a:focus,
.boxstyle-xs.box2 a:focus,
.boxstyle-sm.box2 a:focus,
.boxstyle-md.box2 a:focus,
.boxstyle-lg.box2 a:focus,
.boxstyle_.box2 a:active,
.boxstyle-xs.box2 a:active,
.boxstyle-sm.box2 a:active,
.boxstyle-md.box2 a:active,
.boxstyle-lg.box2 a:active {
  color: #000000;
}
.boxstyle_.box2 .popover button.close,
.boxstyle-xs.box2 .popover button.close,
.boxstyle-sm.box2 .popover button.close,
.boxstyle-md.box2 .popover button.close,
.boxstyle-lg.box2 .popover button.close {
  color: #202020;
}
.boxstyle_.box2 .popover button.close:hover,
.boxstyle-xs.box2 .popover button.close:hover,
.boxstyle-sm.box2 .popover button.close:hover,
.boxstyle-md.box2 .popover button.close:hover,
.boxstyle-lg.box2 .popover button.close:hover {
  color: #000000;
}
.boxstyle_.box2 a:hover .new-window-link,
.boxstyle-xs.box2 a:hover .new-window-link,
.boxstyle-sm.box2 a:hover .new-window-link,
.boxstyle-md.box2 a:hover .new-window-link,
.boxstyle-lg.box2 a:hover .new-window-link,
.boxstyle_.box2 a:hover .insecure-link,
.boxstyle-xs.box2 a:hover .insecure-link,
.boxstyle-sm.box2 a:hover .insecure-link,
.boxstyle-md.box2 a:hover .insecure-link,
.boxstyle-lg.box2 a:hover .insecure-link {
  color: #000000;
}
.boxstyle_.box2 .btn-link,
.boxstyle-xs.box2 .btn-link,
.boxstyle-sm.box2 .btn-link,
.boxstyle-md.box2 .btn-link,
.boxstyle-lg.box2 .btn-link {
  color: #202020;
}
.boxstyle_.box2 .btn-link:hover,
.boxstyle-xs.box2 .btn-link:hover,
.boxstyle-sm.box2 .btn-link:hover,
.boxstyle-md.box2 .btn-link:hover,
.boxstyle-lg.box2 .btn-link:hover,
.boxstyle_.box2 .btn-link:focus,
.boxstyle-xs.box2 .btn-link:focus,
.boxstyle-sm.box2 .btn-link:focus,
.boxstyle-md.box2 .btn-link:focus,
.boxstyle-lg.box2 .btn-link:focus {
  color: #000000;
}
.boxstyle_.box2 a,
.boxstyle-xs.box2 a,
.boxstyle-sm.box2 a,
.boxstyle-md.box2 a,
.boxstyle-lg.box2 a,
.boxstyle_.box2 a:link,
.boxstyle-xs.box2 a:link,
.boxstyle-sm.box2 a:link,
.boxstyle-md.box2 a:link,
.boxstyle-lg.box2 a:link,
.boxstyle_.box2 a:visited,
.boxstyle-xs.box2 a:visited,
.boxstyle-sm.box2 a:visited,
.boxstyle-md.box2 a:visited,
.boxstyle-lg.box2 a:visited {
  -webkit-text-decoration-color: #636363;
  text-decoration-color: #636363;
}
.boxstyle_.box2 a:hover,
.boxstyle-xs.box2 a:hover,
.boxstyle-sm.box2 a:hover,
.boxstyle-md.box2 a:hover,
.boxstyle-lg.box2 a:hover,
.boxstyle_.box2 a:focus,
.boxstyle-xs.box2 a:focus,
.boxstyle-sm.box2 a:focus,
.boxstyle-md.box2 a:focus,
.boxstyle-lg.box2 a:focus,
.boxstyle_.box2 a:active,
.boxstyle-xs.box2 a:active,
.boxstyle-sm.box2 a:active,
.boxstyle-md.box2 a:active,
.boxstyle-lg.box2 a:active {
  -webkit-text-decoration-color: #000000;
  text-decoration-color: #000000;
}
.boxstyle-xs.box3 {
  background: none;
  border: 1px solid #41748D;
  color: inherit;
}
@media (min-width: 768px) {
  .boxstyle_.box3,
  .boxstyle-sm.box3 {
    background: none;
    border: 1px solid #41748D;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .boxstyle-md.box3 {
    background: none;
    border: 1px solid #41748D;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .boxstyle-lg.box3 {
    background: none;
    border: 1px solid #41748D;
    color: inherit;
  }
}
.boxstyle-xs.box4 {
  background: none;
  border: 1px solid #777777;
  color: inherit;
}
@media (min-width: 768px) {
  .boxstyle_.box4,
  .boxstyle-sm.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 992px) {
  .boxstyle-md.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (min-width: 1200px) {
  .boxstyle-lg.box4 {
    background: none;
    border: 1px solid #777777;
    color: inherit;
  }
}
@media (max-width: 767px) {
  .boxstyle_.box5,
  .boxstyle-sm.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 991px) {
  .boxstyle-md.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
@media (max-width: 1199px) {
  .boxstyle-lg.box5 {
    padding: 20px;
    margin-left: -20px;
    margin-right: -20px;
  }
}
.boxstyle_.box5,
.boxstyle-xs.box5,
.boxstyle-sm.box5,
.boxstyle-md.box5,
.boxstyle-lg.box5 {
  border: 0;
  background: #41748D;
  color: white;
}
@media print {
  .boxstyle_.box5,
  .boxstyle-xs.box5,
  .boxstyle-sm.box5,
  .boxstyle-md.box5,
  .boxstyle-lg.box5 {
    border: 1px solid #41748D;
  }
}
.boxstyle_.box5 h1,
.boxstyle-xs.box5 h1,
.boxstyle-sm.box5 h1,
.boxstyle-md.box5 h1,
.boxstyle-lg.box5 h1,
.boxstyle_.box5 h2,
.boxstyle-xs.box5 h2,
.boxstyle-sm.box5 h2,
.boxstyle-md.box5 h2,
.boxstyle-lg.box5 h2,
.boxstyle_.box5 h3,
.boxstyle-xs.box5 h3,
.boxstyle-sm.box5 h3,
.boxstyle-md.box5 h3,
.boxstyle-lg.box5 h3,
.boxstyle_.box5 h4,
.boxstyle-xs.box5 h4,
.boxstyle-sm.box5 h4,
.boxstyle-md.box5 h4,
.boxstyle-lg.box5 h4,
.boxstyle_.box5 h5,
.boxstyle-xs.box5 h5,
.boxstyle-sm.box5 h5,
.boxstyle-md.box5 h5,
.boxstyle-lg.box5 h5,
.boxstyle_.box5 h6,
.boxstyle-xs.box5 h6,
.boxstyle-sm.box5 h6,
.boxstyle-md.box5 h6,
.boxstyle-lg.box5 h6,
.boxstyle_.box5 .h1,
.boxstyle-xs.box5 .h1,
.boxstyle-sm.box5 .h1,
.boxstyle-md.box5 .h1,
.boxstyle-lg.box5 .h1,
.boxstyle_.box5 .h2,
.boxstyle-xs.box5 .h2,
.boxstyle-sm.box5 .h2,
.boxstyle-md.box5 .h2,
.boxstyle-lg.box5 .h2,
.boxstyle_.box5 .h3,
.boxstyle-xs.box5 .h3,
.boxstyle-sm.box5 .h3,
.boxstyle-md.box5 .h3,
.boxstyle-lg.box5 .h3,
.boxstyle_.box5 .h4,
.boxstyle-xs.box5 .h4,
.boxstyle-sm.box5 .h4,
.boxstyle-md.box5 .h4,
.boxstyle-lg.box5 .h4,
.boxstyle_.box5 .h5,
.boxstyle-xs.box5 .h5,
.boxstyle-sm.box5 .h5,
.boxstyle-md.box5 .h5,
.boxstyle-lg.box5 .h5,
.boxstyle_.box5 .h6,
.boxstyle-xs.box5 .h6,
.boxstyle-sm.box5 .h6,
.boxstyle-md.box5 .h6,
.boxstyle-lg.box5 .h6 {
  color: white;
}
.boxstyle_.box5 blockquote.quotes,
.boxstyle-xs.box5 blockquote.quotes,
.boxstyle-sm.box5 blockquote.quotes,
.boxstyle-md.box5 blockquote.quotes,
.boxstyle-lg.box5 blockquote.quotes {
  color: inherit;
}
.boxstyle_.box5 a,
.boxstyle-xs.box5 a,
.boxstyle-sm.box5 a,
.boxstyle-md.box5 a,
.boxstyle-lg.box5 a,
.boxstyle_.box5 a:link,
.boxstyle-xs.box5 a:link,
.boxstyle-sm.box5 a:link,
.boxstyle-md.box5 a:link,
.boxstyle-lg.box5 a:link,
.boxstyle_.box5 a:visited,
.boxstyle-xs.box5 a:visited,
.boxstyle-sm.box5 a:visited,
.boxstyle-md.box5 a:visited,
.boxstyle-lg.box5 a:visited {
  color: white;
}
.boxstyle_.box5 a:hover,
.boxstyle-xs.box5 a:hover,
.boxstyle-sm.box5 a:hover,
.boxstyle-md.box5 a:hover,
.boxstyle-lg.box5 a:hover,
.boxstyle_.box5 a:focus,
.boxstyle-xs.box5 a:focus,
.boxstyle-sm.box5 a:focus,
.boxstyle-md.box5 a:focus,
.boxstyle-lg.box5 a:focus,
.boxstyle_.box5 a:active,
.boxstyle-xs.box5 a:active,
.boxstyle-sm.box5 a:active,
.boxstyle-md.box5 a:active,
.boxstyle-lg.box5 a:active {
  color: #cccccc;
}
.boxstyle_.box5 .popover button.close,
.boxstyle-xs.box5 .popover button.close,
.boxstyle-sm.box5 .popover button.close,
.boxstyle-md.box5 .popover button.close,
.boxstyle-lg.box5 .popover button.close {
  color: white;
}
.boxstyle_.box5 .popover button.close:hover,
.boxstyle-xs.box5 .popover button.close:hover,
.boxstyle-sm.box5 .popover button.close:hover,
.boxstyle-md.box5 .popover button.close:hover,
.boxstyle-lg.box5 .popover button.close:hover {
  color: #cccccc;
}
.boxstyle_.box5 a:hover .new-window-link,
.boxstyle-xs.box5 a:hover .new-window-link,
.boxstyle-sm.box5 a:hover .new-window-link,
.boxstyle-md.box5 a:hover .new-window-link,
.boxstyle-lg.box5 a:hover .new-window-link,
.boxstyle_.box5 a:hover .insecure-link,
.boxstyle-xs.box5 a:hover .insecure-link,
.boxstyle-sm.box5 a:hover .insecure-link,
.boxstyle-md.box5 a:hover .insecure-link,
.boxstyle-lg.box5 a:hover .insecure-link {
  color: #cccccc;
}
.boxstyle_.box5 .btn-link,
.boxstyle-xs.box5 .btn-link,
.boxstyle-sm.box5 .btn-link,
.boxstyle-md.box5 .btn-link,
.boxstyle-lg.box5 .btn-link {
  color: white;
}
.boxstyle_.box5 .btn-link:hover,
.boxstyle-xs.box5 .btn-link:hover,
.boxstyle-sm.box5 .btn-link:hover,
.boxstyle-md.box5 .btn-link:hover,
.boxstyle-lg.box5 .btn-link:hover,
.boxstyle_.box5 .btn-link:focus,
.boxstyle-xs.box5 .btn-link:focus,
.boxstyle-sm.box5 .btn-link:focus,
.boxstyle-md.box5 .btn-link:focus,
.boxstyle-lg.box5 .btn-link:focus {
  color: #cccccc;
}
.boxstyle_.box5 a,
.boxstyle-xs.box5 a,
.boxstyle-sm.box5 a,
.boxstyle-md.box5 a,
.boxstyle-lg.box5 a,
.boxstyle_.box5 a:link,
.boxstyle-xs.box5 a:link,
.boxstyle-sm.box5 a:link,
.boxstyle-md.box5 a:link,
.boxstyle-lg.box5 a:link,
.boxstyle_.box5 a:visited,
.boxstyle-xs.box5 a:visited,
.boxstyle-sm.box5 a:visited,
.boxstyle-md.box5 a:visited,
.boxstyle-lg.box5 a:visited {
  -webkit-text-decoration-color: #ffffff;
  text-decoration-color: #ffffff;
}
.boxstyle_.box5 a:hover,
.boxstyle-xs.box5 a:hover,
.boxstyle-sm.box5 a:hover,
.boxstyle-md.box5 a:hover,
.boxstyle-lg.box5 a:hover,
.boxstyle_.box5 a:focus,
.boxstyle-xs.box5 a:focus,
.boxstyle-sm.box5 a:focus,
.boxstyle-md.box5 a:focus,
.boxstyle-lg.box5 a:focus,
.boxstyle_.box5 a:active,
.boxstyle-xs.box5 a:active,
.boxstyle-sm.box5 a:active,
.boxstyle-md.box5 a:active,
.boxstyle-lg.box5 a:active {
  -webkit-text-decoration-color: #cccccc;
  text-decoration-color: #cccccc;
}
.pagination > li a,
.pagination > li span {
  color: #41748D;
}
.pagination > li.active > a,
.pagination > li.active > span:not(.sr-only),
.pagination > li.active > a:hover,
.pagination > li.active > span:not(.sr-only):hover,
.pagination > li.active > a:focus,
.pagination > li.active > span:not(.sr-only):focus {
  background-color: #41748D;
  border-color: #41748D;
  color: white;
}
.nav-tabs {
  border-bottom-color: #a0bac6;
}
.nav-tabs > li > a {
  background-color: #d9e3e8;
  border-color: #a0bac6;
  transition: background 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.nav-tabs > li > a::before {
  display: block;
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  background: #8dacbb;
  top: 0;
  left: 0;
  transition: width 0.3s cubic-bezier(0.44, 0, 0.35, 1);
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: #ecf1f4;
  border-color: #b3c7d1 #b3c7d1 #a0bac6;
}
.nav-tabs > li > a:hover::before,
.nav-tabs > li > a:focus::before {
  width: 100%;
}
.nav-tabs > li.open > a,
.nav-tabs > li.open > a:hover,
.nav-tabs > li.open > a:focus {
  background-color: #ecf1f4;
  border-color: #b3c7d1 #b3c7d1 #a0bac6;
}
.nav-tabs > li.open > a::before,
.nav-tabs > li.open > a:hover::before,
.nav-tabs > li.open > a:focus::before {
  background: #41748D;
  width: 100%;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  border-color: #a0bac6 #a0bac6 transparent;
}
.nav-tabs > li.active > a::before,
.nav-tabs > li.active > a:hover::before,
.nav-tabs > li.active > a:focus::before {
  background: #41748D;
  width: 100%;
}
.nav-tabs > li.disabled > a {
  color: #555;
}
.nav-tabs > li.disabled > a:hover,
.nav-tabs > li.disabled > a:focus {
  background-color: #d9e3e8;
}
.nav-tabs > li.disabled > a::before {
  background: transparent;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #41748D;
  color: white;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #41748D;
  border-color: #41748D;
  color: white;
}
.id7-koan-spinner__neon--top,
.id7-koan-spinner__neon--bottom {
  stroke: #41748D;
}
.hll {
  background-color: #ffffcc;
}
/*{ background: #f0f3f3; }*/
.c {
  color: #999;
}
/* Comment */
.err {
  color: #AA0000;
  background-color: #FFAAAA;
}
/* Error */
.k {
  color: #006699;
}
/* Keyword */
.o {
  color: #555555;
}
/* Operator */
.cm {
  color: #999;
}
/* Comment.Multiline */
/* Edited to remove italics and make into comment */
.cp {
  color: #009999;
}
/* Comment.Preproc */
.c1 {
  color: #999;
}
/* Comment.Single */
.cs {
  color: #999;
}
/* Comment.Special */
.gd {
  background-color: #FFCCCC;
  border: 1px solid #CC0000;
}
/* Generic.Deleted */
.ge {
  font-style: italic;
}
/* Generic.Emph */
.gr {
  color: #FF0000;
}
/* Generic.Error */
.gh {
  color: #003300;
}
/* Generic.Heading */
.gi {
  background-color: #CCFFCC;
  border: 1px solid #00CC00;
}
/* Generic.Inserted */
.go {
  color: #AAAAAA;
}
/* Generic.Output */
.gp {
  color: #000099;
}
/* Generic.Prompt */
/* Generic.Strong */
.gu {
  color: #003300;
}
/* Generic.Subheading */
.gt {
  color: #99CC66;
}
/* Generic.Traceback */
.kc {
  color: #006699;
}
/* Keyword.Constant */
.kd {
  color: #006699;
}
/* Keyword.Declaration */
.kn {
  color: #006699;
}
/* Keyword.Namespace */
.kp {
  color: #006699;
}
/* Keyword.Pseudo */
.kr {
  color: #006699;
}
/* Keyword.Reserved */
.kt {
  color: #007788;
}
/* Keyword.Type */
.m {
  color: #FF6600;
}
/* Literal.Number */
.s {
  color: #d44950;
}
/* Literal.String */
.na {
  color: #4f9fcf;
}
/* Name.Attribute */
.nb {
  color: #336666;
}
/* Name.Builtin */
.nc {
  color: #00AA88;
}
/* Name.Class */
.no {
  color: #336600;
}
/* Name.Constant */
.nd {
  color: #9999FF;
}
/* Name.Decorator */
.ni {
  color: #999999;
}
/* Name.Entity */
.ne {
  color: #CC0000;
}
/* Name.Exception */
.nf {
  color: #CC00FF;
}
/* Name.Function */
.nl {
  color: #9999FF;
}
/* Name.Label */
.nn {
  color: #00CCFF;
}
/* Name.Namespace */
.nt {
  color: #2f6f9f;
}
/* Name.Tag */
.nv {
  color: #003333;
}
/* Name.Variable */
.ow {
  color: #000000;
}
/* Operator.Word */
.w {
  color: #bbbbbb;
}
/* Text.Whitespace */
.mf {
  color: #FF6600;
}
/* Literal.Number.Float */
.mh {
  color: #FF6600;
}
/* Literal.Number.Hex */
.mi {
  color: #FF6600;
}
/* Literal.Number.Integer */
.mo {
  color: #FF6600;
}
/* Literal.Number.Oct */
.sb {
  color: #CC3300;
}
/* Literal.String.Backtick */
.sc {
  color: #CC3300;
}
/* Literal.String.Char */
.sd {
  color: #CC3300;
  font-style: italic;
}
/* Literal.String.Doc */
.s2 {
  color: #CC3300;
}
/* Literal.String.Double */
.se {
  color: #CC3300;
}
/* Literal.String.Escape */
.sh {
  color: #CC3300;
}
/* Literal.String.Heredoc */
.si {
  color: #AA0000;
}
/* Literal.String.Interpol */
.sx {
  color: #CC3300;
}
/* Literal.String.Other */
.sr {
  color: #33AAAA;
}
/* Literal.String.Regex */
.s1 {
  color: #CC3300;
}
/* Literal.String.Single */
.ss {
  color: #FFCC33;
}
/* Literal.String.Symbol */
.bp {
  color: #336666;
}
/* Name.Builtin.Pseudo */
.vc {
  color: #003333;
}
/* Name.Variable.Class */
.vg {
  color: #003333;
}
/* Name.Variable.Global */
.vi {
  color: #003333;
}
/* Name.Variable.Instance */
.il {
  color: #FF6600;
}
/* Literal.Number.Integer.Long */
.css .o,
.css .o + .nt,
.css .nt + .nt {
  color: #999;
}
/*
 * Side navigation
 *
 * Scrollspy and affixed enhanced navigation to highlight sections and secondary
 * sections of docs content.
 */
/* By default it's not affixed in mobile views, so undo that */
.id7-docs-sidebar.affix {
  position: static;
}
@media (min-width: 768px) {
  .id7-docs-sidebar {
    padding-left: 20px;
  }
}
/* First level of nav */
.id7-docs-sidenav {
  margin-top: 20px;
  margin-bottom: 20px;
}
/* All levels of nav */
.id7-docs-sidebar .nav > li > a {
  display: block;
  padding: 4px 20px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #999;
}
.id7-docs-sidebar .nav > li > a:hover,
.id7-docs-sidebar .nav > li > a:focus {
  padding-left: 19px;
  color: #563d7c;
  text-decoration: none;
  background-color: transparent;
  border-left: 1px solid #563d7c;
}
.id7-docs-sidebar .nav > .active > a,
.id7-docs-sidebar .nav > .active:hover > a,
.id7-docs-sidebar .nav > .active:focus > a {
  padding-left: 18px;
  font-weight: bold;
  color: #563d7c;
  background-color: transparent;
  border-left: 2px solid #563d7c;
}
/* Nav: second level (shown on .active) */
.id7-docs-sidebar .nav .nav {
  display: none;
  /* Hide by default, but at >768px, show it */
  padding-bottom: 10px;
}
.id7-docs-sidebar .nav .nav > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  font-size: 1.4rem;
  font-weight: normal;
}
.id7-docs-sidebar .nav .nav > li > a:hover,
.id7-docs-sidebar .nav .nav > li > a:focus {
  padding-left: 29px;
}
.id7-docs-sidebar .nav .nav > .active > a,
.id7-docs-sidebar .nav .nav > .active:hover > a,
.id7-docs-sidebar .nav .nav > .active:focus > a {
  padding-left: 28px;
  font-weight: 500;
}
/* Show and affix the side nav when space allows it */
@media (min-width: 992px) {
  .id7-docs-sidebar .nav > .active > ul {
    display: block;
  }
  /* Widen the fixed sidebar */
  .id7-docs-sidebar.affix,
  .id7-docs-sidebar.affix-bottom {
    width: 213px;
  }
  .id7-docs-sidebar.affix {
    position: fixed;
    /* Undo the static from mobile first approach */
    top: 10px;
  }
  .id7-docs-sidebar.affix-bottom {
    position: absolute;
    /* Undo the static from mobile first approach */
  }
  .id7-docs-sidebar.affix-bottom .id7-docs-sidenav,
  .id7-docs-sidebar.affix .id7-docs-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  /* Widen the fixed sidebar again */
  .id7-docs-sidebar.affix-bottom,
  .id7-docs-sidebar.affix {
    width: 263px;
  }
}
.id7-docs-palette-logo.purple {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.purple::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #3C1053;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #3C1053;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #3C1053;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #3C1053;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #502864;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #502864;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #634075;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #634075;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #775887;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #775887;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8a7098;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8a7098;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #9e88a9;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #9e88a9;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b19fba;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b19fba;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c4b7cb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c4b7cb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d8cfdd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d8cfdd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ece7ee;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ece7ee;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.purple.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.purple.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5f3f6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.purple.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5f3f6;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.gray::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #58595B;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #58595B;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #58595B;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #58595B;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #696a6b;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #696a6b;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #797a7c;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #797a7c;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8a8b8c;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8a8b8c;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #9b9b9d;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #9b9b9d;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #acacad;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #acacad;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #bcbdbd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #bcbdbd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cdcdce;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cdcdce;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #dedede;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #dedede;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #eeeeef;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #eeeeef;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gray.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gray.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f7f7f7;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gray.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f7f7f7;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.gold::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #D69A2D;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #D69A2D;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #D69A2D;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #D69A2D;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #daa442;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #daa442;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #deae57;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #deae57;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e2b86c;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e2b86c;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e6c281;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e6c281;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ebcc96;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ebcc96;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #efd7ab;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #efd7ab;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f3e1c0;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f3e1c0;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f7ebd5;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f7ebd5;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fbf5ea;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fbf5ea;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fdfaf4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fdfaf4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.gold-bright::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #F1BE48;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #F1BE48;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #F1BE48;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #F1BE48;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f2c55a;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f2c55a;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f4cb6d;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f4cb6d;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5d27f;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5d27f;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f7d891;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f7d891;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f8dfa3;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f8dfa3;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f9e5b6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f9e5b6;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fbebc8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fbebc8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fcf2da;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fcf2da;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fef9ed;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fef9ed;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.gold-bright.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fefcf6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.gold-bright.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fefcf6;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.orange::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #BE531C;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #BE531C;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #BE531C;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #BE531C;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c56433;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c56433;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cb7549;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cb7549;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d28760;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d28760;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d89877;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d89877;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #dfa98e;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #dfa98e;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e5baa4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e5baa4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ebcbbb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ebcbbb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f2ddd2;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f2ddd2;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f9eee8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f9eee8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fcf6f4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fcf6f4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.orange-bright::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #E87722;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #E87722;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #E87722;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #E87722;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ea8538;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ea8538;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ed924e;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ed924e;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #efa064;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #efa064;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f1ad7a;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f1ad7a;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f4bb91;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f4bb91;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f6c9a7;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f6c9a7;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f8d6bd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f8d6bd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fae4d3;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fae4d3;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fdf1e9;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fdf1e9;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.orange-bright.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fef8f4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.orange-bright.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fef8f4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.red::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #9D2235;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #9D2235;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #9D2235;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #9D2235;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a73849;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a73849;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b14e5d;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b14e5d;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ba6472;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ba6472;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c47a86;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c47a86;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ce919a;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ce919a;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d8a7ae;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d8a7ae;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e2bdc2;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e2bdc2;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ebd3d7;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ebd3d7;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5e9eb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5e9eb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #faf4f5;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #faf4f5;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.red-bright::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #CB333B;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #CB333B;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #CB333B;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #CB333B;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d0474f;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d0474f;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d55c62;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d55c62;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #db7076;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #db7076;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e08589;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e08589;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e5999d;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e5999d;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #eaadb1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #eaadb1;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #efc2c4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #efc2c4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5d6d8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5d6d8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #faebeb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #faebeb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.red-bright.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.red-bright.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fcf5f5;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.red-bright.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #fcf5f5;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.teal::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #507F70;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #507F70;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #507F70;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #507F70;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #628c7e;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #628c7e;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #73998d;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #73998d;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #85a59b;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #85a59b;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #96b2a9;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #96b2a9;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a8bfb8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a8bfb8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b9ccc6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b9ccc6;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cbd9d4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cbd9d4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #dce5e2;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #dce5e2;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #eef2f1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #eef2f1;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f6f9f8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f6f9f8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.teal-bright::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #6DCDB8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #6DCDB8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #6DCDB8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #6DCDB8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #7cd2bf;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #7cd2bf;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8ad7c6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8ad7c6;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #99dccd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #99dccd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a7e1d4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a7e1d4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b6e6db;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b6e6db;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c5ebe3;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c5ebe3;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d3f0ea;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d3f0ea;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e2f5f1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e2f5f1;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f0faf8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f0faf8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.teal-bright.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f8fdfb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.teal-bright.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f8fdfb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.blue::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #41748D;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #41748D;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #41748D;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #41748D;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #548298;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #548298;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #6790a4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #6790a4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #7a9eaf;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #7a9eaf;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8dacbb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #8dacbb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a0bac6;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #a0bac6;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b3c7d1;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b3c7d1;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c6d5dd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #c6d5dd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d9e3e8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #d9e3e8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ecf1f4;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #ecf1f4;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5f8f9;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f5f8f9;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright {
  position: relative;
  height: 80px;
  width: 162.4784px;
  margin-right: 10px;
}
.id7-docs-palette-logo.blue-bright::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #00A9CE;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 45.64px solid transparent;
  border-right: 45.64px solid transparent;
  border-top: 80px solid #00A9CE;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-100 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-100::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #00A9CE;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-100::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #00A9CE;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-90 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-90::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #19b2d3;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-90::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #19b2d3;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-80 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-80::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #33bad8;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-80::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #33bad8;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-70 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-70::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #4dc3dd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-70::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #4dc3dd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-60 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-60::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #66cbe2;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-60::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #66cbe2;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-50 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-50::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #80d4e7;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-50::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #80d4e7;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-40 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-40::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #99ddeb;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-40::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #99ddeb;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-30 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-30::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b3e5f0;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-30::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #b3e5f0;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-20 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-20::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cceef5;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-20::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #cceef5;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-10 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-10::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e6f6fa;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-10::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #e6f6fa;
  position: absolute;
  top: 0px;
  right: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-5 {
  position: relative;
  height: 30px;
  width: 60.9294px;
}
.id7-docs-palette-logo.blue-bright.tint-5::before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f2fbfd;
  position: absolute;
  top: 0px;
  left: 0px;
}
.id7-docs-palette-logo.blue-bright.tint-5::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-left: 17.115px solid transparent;
  border-right: 17.115px solid transparent;
  border-top: 30px solid #f2fbfd;
  position: absolute;
  top: 0px;
  right: 0px;
}
.swatch {
  padding-left: 5px;
}
.swatch.purple.primary {
  background-color: #3C1053;
  color: white;
}
.swatch.purple.secondary {
  background-color: #775887;
  color: white;
}
.swatch.gray.primary {
  background-color: #58595B;
  color: white;
}
.swatch.gray.secondary {
  background-color: #8a8b8c;
  color: white;
}
.swatch.red.primary {
  background-color: #9D2235;
  color: white;
}
.swatch.red.secondary {
  background-color: #ba6472;
  color: white;
}
.swatch.blue.primary {
  background-color: #41748D;
  color: white;
}
.swatch.blue.secondary {
  background-color: #7a9eaf;
  color: white;
}
.swatch.teal.primary {
  background-color: #507F70;
  color: white;
}
.swatch.teal.secondary {
  background-color: #84a59b;
  color: white;
}
