@import (reference) '../../../bootstrap/less/variables.less';
@import (reference) '../../../bootstrap/less/mixins.less';
@import (reference) '../../../less/variables.less';
@import (reference) '../../../less/mixins.less';

.apply-site-imagery("/dist/docs/site/docs-site");
.apply-brand(@id7-brand-blue);

// Syntax highlighting
.hll { background-color: #ffffcc }
/*{ background: #f0f3f3; }*/
.c { color: #999; } /* Comment */
.err { color: #AA0000; background-color: #FFAAAA } /* Error */
.k { color: #006699; } /* Keyword */
.o { color: #555555 } /* Operator */
.cm { color: #999; } /* Comment.Multiline */ /* Edited to remove italics and make into comment */
.cp { color: #009999 } /* Comment.Preproc */
.c1 { color: #999; } /* Comment.Single */
.cs { color: #999; } /* Comment.Special */
.gd { background-color: #FFCCCC; border: 1px solid #CC0000 } /* Generic.Deleted */
.ge { font-style: italic } /* Generic.Emph */
.gr { color: #FF0000 } /* Generic.Error */
.gh { color: #003300; } /* Generic.Heading */
.gi { background-color: #CCFFCC; border: 1px solid #00CC00 } /* Generic.Inserted */
.go { color: #AAAAAA } /* Generic.Output */
.gp { color: #000099; } /* Generic.Prompt */
.gs { } /* Generic.Strong */
.gu { color: #003300; } /* Generic.Subheading */
.gt { color: #99CC66 } /* Generic.Traceback */
.kc { color: #006699; } /* Keyword.Constant */
.kd { color: #006699; } /* Keyword.Declaration */
.kn { color: #006699; } /* Keyword.Namespace */
.kp { color: #006699 } /* Keyword.Pseudo */
.kr { color: #006699; } /* Keyword.Reserved */
.kt { color: #007788; } /* Keyword.Type */
.m { color: #FF6600 } /* Literal.Number */
.s { color: #d44950 } /* Literal.String */
.na { color: #4f9fcf } /* Name.Attribute */
.nb { color: #336666 } /* Name.Builtin */
.nc { color: #00AA88; } /* Name.Class */
.no { color: #336600 } /* Name.Constant */
.nd { color: #9999FF } /* Name.Decorator */
.ni { color: #999999; } /* Name.Entity */
.ne { color: #CC0000; } /* Name.Exception */
.nf { color: #CC00FF } /* Name.Function */
.nl { color: #9999FF } /* Name.Label */
.nn { color: #00CCFF; } /* Name.Namespace */
.nt { color: #2f6f9f; } /* Name.Tag */
.nv { color: #003333 } /* Name.Variable */
.ow { color: #000000; } /* Operator.Word */
.w { color: #bbbbbb } /* Text.Whitespace */
.mf { color: #FF6600 } /* Literal.Number.Float */
.mh { color: #FF6600 } /* Literal.Number.Hex */
.mi { color: #FF6600 } /* Literal.Number.Integer */
.mo { color: #FF6600 } /* Literal.Number.Oct */
.sb { color: #CC3300 } /* Literal.String.Backtick */
.sc { color: #CC3300 } /* Literal.String.Char */
.sd { color: #CC3300; font-style: italic } /* Literal.String.Doc */
.s2 { color: #CC3300 } /* Literal.String.Double */
.se { color: #CC3300; } /* Literal.String.Escape */
.sh { color: #CC3300 } /* Literal.String.Heredoc */
.si { color: #AA0000 } /* Literal.String.Interpol */
.sx { color: #CC3300 } /* Literal.String.Other */
.sr { color: #33AAAA } /* Literal.String.Regex */
.s1 { color: #CC3300 } /* Literal.String.Single */
.ss { color: #FFCC33 } /* Literal.String.Symbol */
.bp { color: #336666 } /* Name.Builtin.Pseudo */
.vc { color: #003333 } /* Name.Variable.Class */
.vg { color: #003333 } /* Name.Variable.Global */
.vi { color: #003333 } /* Name.Variable.Instance */
.il { color: #FF6600 } /* Literal.Number.Integer.Long */

.css .o,
.css .o + .nt,
.css .nt + .nt { color: #999; }

/*
 * Side navigation
 *
 * Scrollspy and affixed enhanced navigation to highlight sections and secondary
 * sections of docs content.
 */

/* By default it's not affixed in mobile views, so undo that */
.id7-docs-sidebar.affix {
  position: static;
}
@media (min-width: 768px) {
  .id7-docs-sidebar {
    padding-left: 20px;
  }
}

/* First level of nav */
.id7-docs-sidenav {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* All levels of nav */
.id7-docs-sidebar .nav > li > a {
  display: block;
  padding: 4px 20px;
  font-size: 1.5rem;
  font-weight: 500;
  color: #999;
}
.id7-docs-sidebar .nav > li > a:hover,
.id7-docs-sidebar .nav > li > a:focus {
  padding-left: 19px;
  color: #563d7c;
  text-decoration: none;
  background-color: transparent;
  border-left: 1px solid #563d7c;
}
.id7-docs-sidebar .nav > .active > a,
.id7-docs-sidebar .nav > .active:hover > a,
.id7-docs-sidebar .nav > .active:focus > a {
  padding-left: 18px;
  font-weight: bold;
  color: #563d7c;
  background-color: transparent;
  border-left: 2px solid #563d7c;
}

/* Nav: second level (shown on .active) */
.id7-docs-sidebar .nav .nav {
  display: none; /* Hide by default, but at >768px, show it */
  padding-bottom: 10px;
}
.id7-docs-sidebar .nav .nav > li > a {
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 30px;
  font-size: 1.4rem;
  font-weight: normal;
}
.id7-docs-sidebar .nav .nav > li > a:hover,
.id7-docs-sidebar .nav .nav > li > a:focus {
  padding-left: 29px;
}
.id7-docs-sidebar .nav .nav > .active > a,
.id7-docs-sidebar .nav .nav > .active:hover > a,
.id7-docs-sidebar .nav .nav > .active:focus > a {
  padding-left: 28px;
  font-weight: 500;
}

/* Show and affix the side nav when space allows it */
@media (min-width: 992px) {
  .id7-docs-sidebar .nav > .active > ul {
    display: block;
  }
  /* Widen the fixed sidebar */
  .id7-docs-sidebar.affix,
  .id7-docs-sidebar.affix-bottom {
    width: 213px;
  }
  .id7-docs-sidebar.affix {
    position: fixed; /* Undo the static from mobile first approach */
    top: 10px;
  }
  .id7-docs-sidebar.affix-bottom {
    position: absolute; /* Undo the static from mobile first approach */
  }
  .id7-docs-sidebar.affix-bottom .id7-docs-sidenav,
  .id7-docs-sidebar.affix .id7-docs-sidenav {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  /* Widen the fixed sidebar again */
  .id7-docs-sidebar.affix-bottom,
  .id7-docs-sidebar.affix {
    width: 263px;
  }
}

.docs-palette-logo(@colour) {
  .logo-as-css(auto, 80px, @colour);
  margin-right: 10px;

  &.tint-100 {.logo-as-css(auto, 30px, @colour);}
  &.tint-90 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 90%))); }
  &.tint-80 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 80%))); }
  &.tint-70 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 70%))); }
  &.tint-60 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 60%))); }
  &.tint-50 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 50%))); }
  &.tint-40 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 40%))); }
  &.tint-30 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 30%))); }
  &.tint-20 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 20%))); }
  &.tint-10 { .logo-as-css(auto, 30px, screen(@colour, darken(white, 10%))); }
  &.tint-5  { .logo-as-css(auto, 30px, screen(@colour, darken(white, 5%))); }
}

.id7-docs-palette-logo {
  &.purple { .docs-palette-logo(@id7-brand-purple); }
  &.gray   { .docs-palette-logo(@id7-brand-gray); }
  &.gold   { .docs-palette-logo(@id7-brand-gold); }   &.gold-bright   { .docs-palette-logo(@id7-brand-gold-bright); }
  &.orange { .docs-palette-logo(@id7-brand-orange); } &.orange-bright { .docs-palette-logo(@id7-brand-orange-bright); }
  &.red    { .docs-palette-logo(@id7-brand-red); }    &.red-bright    { .docs-palette-logo(@id7-brand-red-bright); }
  &.teal   { .docs-palette-logo(@id7-brand-teal); }   &.teal-bright   { .docs-palette-logo(@id7-brand-teal-bright); }
  &.blue   { .docs-palette-logo(@id7-brand-blue); }   &.blue-bright   { .docs-palette-logo(@id7-brand-blue-bright); }
}

.swatch-colors(@brandColor) {
  &.primary {
    background-color: @brandColor;
    color: contrast(@brandColor, @text-color, white, 50%)
  }
  &.secondary {
    background-color: screen(@brandColor, lighten(black, 30%));
    color: contrast(screen(@brandColor, lighten(black, 30%)), @text-color, white, 50%);
  }
}

.swatch {
  padding-left: 5px;
  &.purple {
    .swatch-colors(@id7-brand-purple)
  }
  &.gray {
    .swatch-colors(@id7-brand-gray)
  }
  &.red {
    .swatch-colors(@id7-brand-red)
  }
  &.blue {
    .swatch-colors(@id7-brand-blue)
  }
  &.teal {
    .swatch-colors(@id7-brand-teal)
  }
}
